html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: 'Mulish', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &.ReactModal__Body--open{
        overflow: hidden;
        height: 100vh;
        width: 100%;
    }

    .disable-pointer-events {
        pointer-events: none;
    }

    .bold{
        font-weight: 700;
    }

    .nav-container {
        padding-left: 48px;
        padding-right: 48px;

        .navigation {
            margin-bottom: 64px;
            margin-top: 32px;
            max-width: 1920px;

            @media (max-width: 768px){
                flex-wrap: wrap;
            }

            @media (max-width: 540px){
                .page-title{
                    margin-right: 60px;
                }

                a.button{
                    margin: 32px auto 0 auto;
                }
            }

            .page-title {
                color: #2E3257;
                font-family: 'Borna', sans-serif;
                font-size: 20px;
                font-weight: 500;
                letter-spacing: -0.2px;
            }

            .button {
                background: #2E3257;
                border-radius: 100px;
                border: 1px solid #2E3257;
                color: #FFF;
                font-family: 'Borna', sans-serif;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: -0.16px;
                padding: 12px 40px;
                text-decoration: none;
            }
        }

        @media (max-width: 768px) {
            padding-left: 16px;
            padding-right: 16px;
        }
    }

    header {
        background: linear-gradient(180deg, #F6FFFF 0%, #EAEEF3 100%);
        padding-bottom: 48px;

        @media (max-width: 768px) {}

        .text-col {
            padding-left: calc(16px + (36 - 16) * ((100vw - 768px) / (1920 - 768)));

            @media (max-width: 768px) {
                padding-left: 16px;
            }

            h1 {
                color: #2E3257;
                font-family: 'borna', sans-serif;
                font-size: calc(48px + (56 - 48) * ((100vw - 768px) / (1920 - 768)));
                font-weight: 500;
                letter-spacing: 0.56px;
                line-height: calc(50px + (70 - 50) * ((100vw - 768px) / (1920 - 768)));
                margin-bottom: 24px;

                &.blue {
                    color: #5F5C8E;
                    line-height: 60px;
                }

                @media (min-width: 1440px) {
                    font-size: 56px;
                    line-height: 70px;
                }

                @media (max-width: 768px) {
                    font-size: 40px;
                    line-height: 44px !important;
                    margin-bottom: 16px;
                }
            }

            span {
                color: #2E3257;
                font-size: 20px;
                font-weight: 600;
                letter-spacing: -0.2px;

                @media (max-width: 768px) {
                    font-size: 18px;
                }
            }
        }

        img {
            border-radius: 8px;
            height: auto;
            object-fit: cover;
            width: 100%;

            @media (max-width: 540px) {
                margin-bottom: 32px;
                max-height: 320px;
            }
        }
    }

    .section {
        background: #EAEEF3;

        .bio {
            p {
                max-height: 300px;
                // transition: max-height .4s linear;
                position: relative;
                overflow: hidden;

                &::after {
                    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 1) 100%);
                    bottom: 0;
                    content: "";
                    height: 60px;
                    left: 0;
                    position: absolute;
                    width: 100%;
                }

                &.open {
                    max-height: unset;
                    padding-bottom: 60px;
                    // transition: max-height .4s linear;
                }
            }

            button {
                border: 0;
                box-shadow: none;
                background: transparent;
                color: #2E3257;
                text-align: center;
                font-family: 'Mulish', sans-serif;
                font-size: 18px;
                font-weight: 600;
                letter-spacing: -0.18px;
                width: 100%;

                span{
                    font-size: 12px;
                }
            }
        }

        .feed {
            .white-container {
                background: #fff;
                border-radius: 8px;
                margin-bottom: 32px;
                padding: 48px;

                h1.section-title {
                    border-bottom: 1px solid #EAEEF3;
                    font-size: 32px;
                    margin-bottom: 32px;
                    padding-bottom: 32px;
                }

                @media (max-width: 768px) {
                    border-radius: 0;
                    padding: 24px;

                    h1.section-title {
                        font-size: 24px;
                        margin-bottom: 10px;
                        padding-bottom: 24px;
                    }
                }
            }

            .form-container {
                form {
                    h2 {
                        font-family: 'borna', sans-serif;
                        font-size: 24px;
                        font-weight: 500;
                        margin-bottom: 33px;
                    }

                    .input-container {
                        position: relative;

                        #memoriam,
                        input[type="text"] {
                            border: 1px solid #C9C9C9;
                            border-radius: 6px;
                            margin-bottom: 24px;
                            min-height: 88px;
                            padding: 31px;
                            width: 100%;

                            &:focus,
                            &:active {
                                box-shadow: 0 0 2px 0.4px #b9b1b1;
                                outline: none;
                            }
                        }

                        label[for="memoriam"]{
                            margin-top: 3.5rem;
                            z-index: 0;
                        }

                        #memoriam {
                            border: 0;
                            min-height: 300px;
                            padding: 0;

                            .ql-toolbar{
                                border-top-right-radius: 8px;
                                border-top-left-radius: 8px;
                            }
                            
                            .ql-container{
                                background-color: transparent;
                                border-bottom-right-radius: 8px;
                                border-bottom-left-radius: 8px;
                                min-height: 300px;

                                &.active{
                                    background-color: #fff;
                                }

                                .ql-editor{
                                    min-height: 300px;
                                }
                            }
                        }

                        label {
                            font-size: 16px;
                            position: absolute;
                            transform-origin: top left;
                            margin-left: 2rem;
                            margin-top: 2rem;
                            font-weight: 300;
                            transform: scale(0) translateY(1);
                            transition: transform 0.2s linear;
                            z-index: 1;

                            &.animate-label {
                                transform: scale(0.6) translateY(-27px);
                                transition: transform 0.2s linear;
                            }
                        }
                    }

                    .file-upload {
                        margin-bottom: 44px;

                        label:not(.border-0),
                        .label-container {
                            border: 1px solid #C9C9C9;
                            border-radius: 6px;
                            min-height: 200px;
                            padding: 48px 32px 40px 32px;
                            width: 100%;

                            label.no-connect {
                                border: 0;
                                padding: 0;
                                height: 0;
                                min-height: unset;
                                margin: 0 auto;
                                width: 0;
                            }

                            .input-btn-container {
                                border-radius: 100px;
                                border: 1px solid #2E3257;
                                background: #FFF;
                                cursor: pointer;
                                color: #2E3257;
                                text-align: center;
                                line-height: 52px;
                                font-family: 'Borna', sans-serif;
                                font-size: 16px;
                                font-weight: 500;
                                letter-spacing: -0.16px;
                                position: relative;
                                margin-top: 16px;
                                width: 220px;
                                
                                input {
                                    cursor: pointer;
                                    position: absolute;
                                    left: 0;
                                    width: 100%;
                                    height: 100%;
                                }
                            }

                            svg {
                                display: flex;
                                margin: 16px auto;
                                min-height: 48px;
                                min-width: 48px;
                            }

                            span {
                                font-size: 20px;
                                font-weight: 700;
                                line-height: 25px;

                                &.brown {
                                    color: #AA8778;
                                }

                                &.small {
                                    font-size: 16px;
                                    font-weight: 500;
                                    line-height: 20px;
                                    margin-top: 4px;
                                }
                            }
                        }

                        label.border-0{
                            cursor: pointer;
                            width: 100%;
                        }


                        input {
                            color: transparent;

                            &.h-0{
                                height: 0;
                            }

                            &[type=file]::-webkit-file-upload-button {
                                visibility: hidden;
                            }
                        }
                    }

                    .preview-img {
                        border-radius: 8px;
                        cursor: pointer;
                        height: 112px;
                        margin-bottom: 16px;
                        margin-right: 16px;
                        min-height: 112px;
                        overflow: hidden;
                        width: 146px;

                        &.uploaded-img {
                            &:hover {
                                cursor: pointer;

                                .delete-icon {
                                    opacity: 1 !important;
                                }
                            }

                            .delete-icon {
                                opacity: 0;
                                position: absolute;
                                left: 50%;
                                top: 50%;
                                transform: translate(-50%, -50%);
                                cursor: pointer;

                                @media (max-width: 768px){
                                    opacity: 1;
                                }

                                svg {
                                    min-height: 24px;
                                    min-width: 24px;
                                }
                            }
                        }

                        &.add-more-img {
                            border: 1px solid #E1E1E1;

                            svg {
                                transform: scale(1);
                                transition: transform .2s linear;
                            }

                            &:hover {
                                cursor: pointer;

                                svg {
                                    transform: scale(1.1);
                                    transition: transform .2s linear;
                                }
                            }
                        }

                        video{
                            width: 146px;
                        }

                        img {
                            height: 100%;
                            object-fit: cover;
                            object-position: top center;
                            min-width: 100%;
                            width: auto;
                        }
                    }

                    button.sub {
                        background: #2E3257;
                        border-radius: 100px;
                        border: 1px solid #2E3257;
                        padding: 16px 40px;
                        color: #FFF;
                        font-family: 'borna', sans-serif;
                        font-size: 16px;
                        font-weight: 500;
                        width: fit-content;
                    }
                }
            }

            .posts {
                .message {
                    border-bottom: 1px solid #C9C9C9;
                    font-size: 16px;
                    letter-spacing: -0.18px;
                    padding: 32px 0;

                    .time-name {
                        font-size: 18px;
                        font-weight: 700;
                        margin-bottom: 16px;
                    }

                    .time {
                        margin-right: 16px;
                    }

                    .name {
                        margin-left: 16px;
                    }

                    .img {
                        .inner-container {
                            border-radius: 8px;
                            cursor: pointer;
                            // height: 112px;
                            max-height: 420px;
                            margin-right: 16px;
                            margin-top: 16px;
                            position: relative;
                            overflow: hidden;
                            width: 146px;

                            &.video-container::after {
                                border-left: 20px solid transparent;
                                border-right: 20px solid transparent;
                                border-bottom: 33px solid #fff;
                                content: "";
                                height: 0;
                                left: 50%;
                                top: 50%;
                                transform: translate(-50%, -50%) rotate(90deg);
                                position: absolute;
                                width: 0;
                            }

                            video,
                            img {
                                height: 100%;
                                object-fit: cover;
                                object-position: center;
                                width: 100%;
                            }
                        }

                        .video-container {
                            position: relative;
                        }

                        .video {
                            pointer-events: none;
                        }

                        .video-overlay {
                            bottom: 0;
                            background-color: transparent;
                            left: 0;
                            position: absolute;
                            right: 0;
                            top: 0;
                        }
                    }
                }
            }

            button.see-more {
                border: 0;
                box-shadow: none;
                background: transparent;
                color: #2E3257;
                text-align: center;
                font-family: 'Mulish', sans-serif;
                font-size: 18px;
                font-weight: 600;
                letter-spacing: -0.18px;
                width: 100%;

                span{
                    font-size: 12px;
                }
            }
        }
    }

    .ReactModal__Content {
        height: fit-content;
        left: 50% !important;
        transform: translate(-50%, -30%);
        top: 30% !important;
        padding: 50px !important;
        width: fit-content;

        button{
            background-color: transparent;
            border: 0;
            display: block;
            height: 100%;
            left: 0;
            outline: none;
            top: 0;
            position: absolute;
            width: 100px;

            &::after{
                border: solid #000;
                border-width: 0 2px 2px 0;
                content: '';
                cursor: pointer;
                display: inline-block;
                height: 0px;
                left: 20px;
                padding: 4px;
                position: absolute;
                top: 50%;
                transform: rotate(-227deg) translateY(-50%);
                width: 0px;
            }

            &.close{
                display: flex;
                justify-content: flex-end;
                align-items: center;
                height: 50px;
                left: unset;
                right: 20px;
                z-index: 10;

                >div{
                    width: fit-content;
                }

                &::after{
                    content: none;
                }

                span{
                    background: #000;
                    display: block;
                    transform: rotate(45deg) translate(0, 0);
                    height: 2px;
                    width: 24px;
                    
                    &:last-child{
                        transform: rotate(-45deg) translate(1px, -1px);
                    }
                }
            }
            
            &.next{
                left: unset;
                right: 0;

                &::after{
                    left: unset;
                    right: 20px;
                    transform: rotate(-45deg) translateY(-50%) !important;
                }
            }
        }

        video {
            max-height: 90vh;
            max-width: 90vw;
        }
    }
}