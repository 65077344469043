@font-face {
	font-family: 'borna';
	src: url('../fonts/borna-medium.eot');
	src: url('../fonts/borna-medium-webfont.woff') format('woff'),
		url('../fonts/borna-medium-webfont.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

.font-borna{
    font-family: 'borna', sans-serif;
}